<template>
  <div class="ml-4">
    <title-header :title="title" :buttons="[]"></title-header>

    <v-form v-model="validData">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined label="*Nombre" v-model="group.name" :rules="rules.name"></v-text-field>

            <v-text-field
              outlined
              label="Descripción"
              v-model="group.description"
            ></v-text-field>

            <p class="ml-2">
              <small>*Campos obligatarios</small>
            </p>

            <div class="title mb-3">Miembros</div>
            <v-row class="ml-1" align="center" justify="center">
              <v-autocomplete
                outlined
                dense
                hide-details
                :items="filteredUsers"
                v-model="selectedUserId"
                item-text="displayName"
                item-value="id"
                label="Buscar usuarios"
                clearable>
              </v-autocomplete>
                <v-btn
                class="ml-3"
                :disabled="selectedUserId === '' || selectedUserId === undefined"
                outlined
                color="primary"
                @click="addMemberClicked()">
                Agregar
              </v-btn>
            </v-row>

            <v-data-table class="mb-2" :headers="membersHeaders" :items="group.members">
              <template v-slot:item.createdAt="{ item }">{{item.assignedAt | date}}</template>
              <template v-slot:item.buttons="{ item }">
                <v-row justify="end">
                  <v-btn small color="red" outlined @click="removeMemberClicked(item)" dark>Eliminar</v-btn>
                </v-row>
              </template>
            </v-data-table>

            <div class="ml-3 mb-5">
              <small> Fecha de creación: {{group.createdAt | date}} </small>
            </div>

            <v-btn rounded outlined color="primary" dark @click="cancel()">Cancelar</v-btn>
            <v-btn
              class="ml-6 mr-6"
              rounded
              color="primary"
              @click="updateClicked()"
              :disabled="!validData"
            >Actualizar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
    <v-card>
      <v-card-title class="headline">Felicidades</v-card-title>
      <v-card-text>El grupo se ha actualizado satisfactoriamente.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar color="primary" v-model="showSnackbar">
    {{snackBarText}}
    <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
  </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import { GROUPS_SELECT_GROUP } from '@/store/mutations.type'
import rules from '@/constants/input-validation'
import {
  GROUPS_UPDATE_GROUP,
  USERS_GET_USERS,
  GROUPS_REMOVE_GROUP_MEMBER,
  GROUPS_ADD_GROUP_MEMBER,
  GROUPS_GET_GROUP
} from '@/store/actions.type'

export default {
  name: 'group-edit',
  props: {},
  data() {
    return {
      title: 'Editar grupo',
      selectedUserId: '',
      snackbarTimeout: 1000,
      snackBarText: '',
      showSnackbar: false,
      dialog: false,
      validData: false,
      rules,
      membersHeaders: [
        { text: 'Nombre', value: 'displayName' },
        { text: 'Agregado', value: 'createdAt' },
        { text: '', value: 'buttons' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      group: 'getSelectedGroup',
      users: 'getUsers'
    }),
    filteredUsers() {
      let users = this.users
      this.group.members.forEach(member => {
        users = users.filter(user => user.id !== member.id)
      })
      return users
    }
  },
  methods: {
    ...mapActions({
      updateGroup: GROUPS_UPDATE_GROUP,
      getUsers: USERS_GET_USERS,
      getSelectedGroup: GROUPS_GET_GROUP,
      addMember: GROUPS_ADD_GROUP_MEMBER,
      removeMember: GROUPS_REMOVE_GROUP_MEMBER
    }),
    ...mapMutations({
      selectGroup: GROUPS_SELECT_GROUP
    }),
    cancel() {
      this.$router.push('/settings/groups')
    },
    updateClicked() {
      this.updateGroup(this.group)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    },
    addMemberClicked() {
      const index = this.users.findIndex(u => u.id === this.selectedUserId)
      const user = this.users[index]
      const group = this.group
      const params = { group, user }
      this.addMember(params)
        .then(response => {
          this.snackBarText = 'Se agrego el usuario al grupo'
          this.showSnackbar = true
          const groupId = this.$route.params.id
          this.selectedUserId = ''
          this.getSelectedGroup(groupId)
          this.getUsers()
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    },
    removeMemberClicked(user) {
      const group = this.group
      const params = { group, user }
      this.removeMember(params)
        .then(response => {
          this.snackBarText = 'Se removió el usuario del grupo'
          this.showSnackbar = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    }
  },
  created() {
    const groupId = this.$route.params.id
    this.getSelectedGroup(groupId)
    this.getUsers()
  },
  components: {
    TitleHeader
  }
}
</script>

<style>
</style>
